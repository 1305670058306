import React from "react"

const SeparatorBottom = ({ className }) => {
  return (
    <div className={`${className} separator-bottom`}>

    </div>
  )
}

export default SeparatorBottom


