import React from "react"

const SeparatorTop = ({ className }) => {
  return (
    <div className={`${className} separator-top`}>

    </div>
  )
}

export default SeparatorTop


